.container {
  display: flex;
  justify-content: center;
}
.search {
  font-size: 20px;
  /* display: block; */
  /* margin: auto; */
  padding: 0.8rem 1.6rem;
  background-color: inherit;
  /* border-radius: 0.3rem; */
  border: none;
  /* border-bottom: 0.1rem solid #d1a056; */
  border-bottom: 0.1rem solid #0849d685;
}
.search:focus {
  outline: none;
  border-bottom: 0.1rem solid #0849d6;
}
.search:hover {
  border-bottom: 0.1rem solid #0849d6;
}
.search:disabled{
  opacity: 25%;
}
/* .search::placeholder {
  color: slategray;
} */

.deleteSearch {
  cursor: pointer;
  padding: 5px;
  position: relative;
  align-self: center;
  right: 20px;
}
